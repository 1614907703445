<template>
    <div class="float-container">
        <div class="float-menu " :class=" show ? 'float-show' : 'float-hide' ">
            <div class="row">
                <div class="col-12 col-md-8 col-lg-9 col-xl-10 ml-auto pl-md-0">
                    <div class="card animated border-0" :class="classes">
                        <div class="card-body">
                            <slot name="content"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            classes:{
                type: String,
            }
        },
        watch: {
            show() {}
        }
    }
</script>
<style lang="scss">
    .float-container {
        position: relative;
        width: 100%;
        z-index: 3;

        .float-menu {
            transition: all 0.4s linear;
            position: fixed;
            top: 75px;
            right: 0;
            width: 100%;

            .card {
                border-radius: 0 0 .25rem .25rem;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .float-container {
            .float-menu {
                top: 75px;
            }

            .float-hide {
                top: -70px !important;
            }
        }
    }

    .float-hide {
        top: -100px !important;
    }
</style>